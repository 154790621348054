<template>
	<div :style="bg" >
		<Header :title="$t('mall.orderList')"></Header>
		<div class="column-center-content main-content">
			<div v-for="item in orderList" :key="item.id" @click="goDetail(item)" style="margin: 5px 0px; padding: 10px; background-color: #FFFFFF; width: 95%; min-height: 100px; border-radius: 5px; display: flex; flex-direction: row;">
				<img style="margin-right: 10px;" :src="item.img" width="100px"/>
				<div style="width: 100%; display: flex; flex-direction: column; justify-content: space-between;">
					<label style="font-size: 18px; font-weight: bold;">{{item.prodName}}</label>
					<label style="font-size: 14px; color: #999999; margin: 5px 0px;">{{item.specs}}</label>
					<div class="row-content" style="width: 100%; justify-content: space-between;">
						<label style="font-size: 14px; color: #999999;">{{item.gmtCreate}}</label>
						<label style="font-size: 14px; color: #DC3545;">{{item.coastOther}} 
							<span v-if="item.other == 'RPBT'">PBT</span>
							<span v-else>{{item.other}}</span>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../components/NavHeader.vue'
	import { Indicator } from 'mint-ui';
	
	export default {
		name: 'AddressAdd',
		components: {
			Header
		},
		mounted() {
			Indicator.open({
				text: '',
				spinnerType: 'fading-circle'
			});
			
			this.$request.orderList({}).then((data)=>{
				Indicator.close();
				this.$request.mallList({}).then((prod)=>{
					prod.map(temp => {
						data.forEach(tempInner => {
							if(tempInner.prodId == temp.id) {
								tempInner.img = temp.img
								tempInner.specs = temp.specs
							}
						})
					})
					this.orderList = data;
				});				
			});
		},
		data() { 
			return {
				bg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				orderList: [],
				prodList: []
			}
		},
		methods: {
			goDetail(item) {
				this.$router.push({
					name: 'OrderDetail',
					params: item
				})
			}
		}
	}
</script>

<style>
</style>
